import { extname } from 'path'
import { getType } from 'mime'

export const getMediaType = path => {
  const mediaType = getType(path)

  if (mediaType != null) {
    return mediaType
  }

  // Add additional media types to recognize
  const ext = extname(path)
  if (ext === '.jxl') {
    return 'image/jxl'
  }

  return null
}
