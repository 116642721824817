/* eslint-env browser */

import {
  Box,
  Flex,
  Img,
  Skeleton,
  Spinner,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { extname } from 'path'
import { useTranslation } from 'react-i18next'
import { MediaViewer, getSupportedMediaType } from './MediaViewer.js'
import icons from '../lib/icons.json'

// Run once when this file is imported
if (typeof CSS !== 'undefined' && typeof CSS.registerProperty === 'function') {
  try {
    // HACK: Safari 14 throws an exception "SyntaxError: The given initial value
    // does not parse for the given syntax." when CSS.registerProperty's
    // initialValue is a percentage.
    CSS.registerProperty({
      name: '--filelist-progress',
      syntax: '<percentage>',
      inherits: false,
      initialValue: '0%'
    })
    CSS.registerProperty({
      name: '--filelist-r',
      syntax: '<number>',
      inherits: false,
      initialValue: '0'
    })
    CSS.registerProperty({
      name: '--filelist-g',
      syntax: '<number>',
      inherits: false,
      initialValue: '0'
    })
    CSS.registerProperty({
      name: '--filelist-b',
      syntax: '<number>',
      inherits: false,
      initialValue: '0'
    })
  } catch {}
}

function iconUrlForExtension (ext) {
  if (ext.startsWith('.')) ext = ext.slice(1)
  ext = ext.toLowerCase()
  const item = icons.find(obj => obj.exts.includes(ext))

  return item
    ? 'data:image/svg+xml,' + encodeURIComponent(item.icon)
    : iconUrlForExtension('blank')
}

export const FileList = ({ files, isDisabled, onDownload, ...rest }) => {
  if (files == null) files = []
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const [viewerSrc, setViewerSrc] = useState(null)
  const [viewerPath, setViewerPath] = useState(null)

  useEffect(() => {
    // Clean up blob URLs
    if (!viewerSrc?.url.startsWith('blob:')) {
      return
    }

    return () => {
      URL.revokeObjectURL(viewerSrc.url)
    }
  }, [viewerSrc])

  const handleFileClick = async path => {
    const mediaType = getSupportedMediaType(path)

    if (mediaType != null) {
      setViewerPath(path)
      onOpen()
      const previewUrl = await files
        .find(file => file.path === path)
        .getPreviewUrl()
      if (previewUrl == null) {
        // This should only happen on cleanup or error
        handleViewerClose()
        return
      }

      setViewerSrc({
        mediaType: mediaType,
        url: previewUrl
      })
    } else {
      onDownload(path)
    }
  }

  const handleViewerClose = () => {
    onClose()
    setViewerSrc(null)
    setViewerPath(null)
  }

  return (
    <Stack spacing={2} {...rest}>
      {files.length === 0 && (
        <>
          <Skeleton py={2} px={4}>
            placeholder
          </Skeleton>
          <Skeleton py={2} px={4}>
            placeholder
          </Skeleton>
          <Skeleton py={2} px={4}>
            placeholder
          </Skeleton>
        </>
      )}
      {files.map((file, i) => (
        <FileListFile
          key={file.path}
          file={file}
          isDisabled={isDisabled}
          onFileClick={handleFileClick}
        />
      ))}
      <MediaViewer
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={handleViewerClose}
        onDownload={onDownload}
        src={viewerSrc}
        path={viewerPath}
      />
    </Stack>
  )
}

const FileListFile = ({ file, isDisabled, onFileClick }) => {
  const { i18n } = useTranslation()

  // Starting color
  const start = {
    r: 121,
    g: 40,
    b: 202
  }

  // Ending colors when progress is 100%
  const end = {
    r: 255,
    g: 0,
    b: 128
  }

  const progressColor = getProgressColor(start, end, file.progress)

  const iconUrl = iconUrlForExtension(extname(file.name))

  const handleClick = () => {
    if (isDisabled) return
    onFileClick(file.path)
  }

  return (
    <Flex
      as='button'
      textAlign='start'
      onClick={handleClick}
      py={2}
      px={3.5}
      align='center'
      borderRadius='sm'
      backgroundColor='whiteAlpha.300'
      opacity={isDisabled ? 0.6 : 1}
      bgGradient={
        'linear(to-r, ' +
        `rgba(${start.r}, ${start.g}, ${start.b}, var(--filelist-alpha)) 0%, ` +
        'rgba(var(--filelist-r), var(--filelist-g), var(--filelist-b), var(--filelist-alpha)) var(--filelist-progress), ' +
        'rgba(255, 255, 255, 0) var(--filelist-progress)' +
        ')'
      }
      style={{
        '--filelist-progress': `${file.progress * 100}%`,
        '--filelist-r': progressColor.r,
        '--filelist-g': progressColor.g,
        '--filelist-b': progressColor.b
      }}
      transition='--filelist-progress 0.5s linear, --filelist-r 0.5s linear, --filelist-g 0.5s linear, --filelist-b 0.5s linear'
      sx={{
        '--filelist-alpha': 0.7
      }}
      _hover={{
        '--filelist-alpha': 1,
        backgroundColor: 'whiteAlpha.400',
        cursor: isDisabled ? 'not-allowed' : 'pointer'
      }}
    >
      <Img src={iconUrl} boxSize='23px' />
      <Box ms={2.5} flex={1} isTruncated title={file.name}>
        {file.name}
      </Box>
      <Box ms={2} color={file.progress > 0 ? 'white' : 'whiteAlpha.600'}>
        {file.progress > 0 ? (
          <Stack direction='row' align='center' spacing={3}>
            <Box>{i18n.format(Math.floor(file.progress * 100), 'percent')}</Box>
            <Spinner size='sm' speed={file.progress === 1 ? '4s' : undefined} />
          </Stack>
        ) : (
          <Box>{i18n.format(file.length, 'bytes')}</Box>
        )}
      </Box>
    </Flex>
  )
}

function getProgressColor (start, end, progress) {
  if (progress == null) progress = 0

  return {
    r: start.r + (end.r - start.r) * progress,
    g: start.g + (end.g - start.g) * progress,
    b: start.b + (end.b - start.b) * progress
  }
}
